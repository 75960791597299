import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom/dist/index';

import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Card } from 'semantic-ui-react';
import styled from 'styled-components';

import { formatDateDistance } from 'reducers/locale';
import { updateNotificationStatus } from 'store/monitor/notificationsSlice';

import { AnalyticsAwareHoverableIconButtonWithBadgeWithTooltip } from 'components/ui/icon/HoverableIconWithBadge';
import NotificationBody from 'components/ui/notifications/Body';
import NotificationTitle from 'components/ui/notifications/Title';

import { logEvent } from 'utils/analytics';
import { selectPeriodBasedOnDate } from 'utils/date';

import * as svars from 'assets/style/variables';

import getStatusAndEndpoint from './utils';

const CardContainer = styled(Card)`
  cursor: pointer;
  min-width: 100%;
  &&&& {
    margin: ${svars.spaceNormalLarge};
    background-color: ${(props) =>
      props.isRead ? svars.colorLighterGrey : svars.colorWhite};
    transition: background-color ${svars.transitionBase};

    &:hover {
      background-color: ${svars.colorLightGrey};
      transform: none;
    }
  }
`;
const CardMeta = styled(Card.Meta)`
  &&&& {
    font-size: ${svars.fontSizeMedium};
  }
`;
const NotificationIconStatusChange = styled(
  AnalyticsAwareHoverableIconButtonWithBadgeWithTooltip
)`
  position: absolute;
  top: ${svars.spaceSmaller};
  right: ${svars.spaceNormalLarge};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${svars.spaceLarge};
`;
const CompactCardContent = styled(Card.Content)`
  &&&& {
    padding: ${svars.spaceNormalLarge};
  }
`;

function NotificationCard({ id, type, create_date, onToggle, ...otherProps }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dateDistance = formatDateDistance(new Date(create_date), new Date(), {
    addSuffix: true,
  });

  const handleNotificationAction = ({
    notificationId = null,
    notificationIds = [],
    links = {},
    markAsSeen = false,
    toggleSeenStatus = false,
    currentSeenStatus = false,
  }) => {
    const { status, callEndpoint } = getStatusAndEndpoint({
      markAsSeen,
      toggleSeenStatus,
      currentSeenStatus,
    });
    const ids = notificationId ? [notificationId] : notificationIds;

    dispatch(
      updateNotificationStatus({
        notificationIds: ids,
        seen: status,
        callEndpoint,
      })
    );

    if (navigate && (links.providedLink || links.generatedLink)) {
      navigate(links.providedLink || links.generatedLink);
    }
  };

  const handleCardClick = (event, { generatedLink }) => {
    onToggle();
    event.stopPropagation();
    event.preventDefault();
    handleNotificationAction({
      notificationId: id,
      links: { providedLink: otherProps.providedLink, generatedLink },
      markAsSeen: true,
      currentSeenStatus: otherProps.seen,
    });
    logEvent({
      category: 'Notifications',
      action: 'Click notification',
      label: `${type}`,
    });
  };

  const handleIconClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    handleNotificationAction({
      notificationId: id,
      toggleSeenStatus: true,
      currentSeenStatus: otherProps.seen,
    });
  };

  const linkMap = {
    PresetUserNotification: () =>
      `/facets/analyze/${otherProps.preset?.view_facet?.id}/search?preset=${otherProps.preset?.id}`,
    CampaignPresetUserNotification: () =>
      `/campaign/${otherProps.preset?.campaign?.id}/monitor/dashboard?preset=${
        otherProps.preset?.id
      }&period=${selectPeriodBasedOnDate(create_date)}`,
  };

  const link = otherProps.providedLink || linkMap[type]();

  return (
    <CardContainer
      onClick={(event) =>
        handleCardClick(event, {
          id,
          generatedLink: link,
        })
      }
      {...(link && { href: link })}
      isRead={otherProps.seen}
      color={svars.colorLightGrey}
    >
      <CompactCardContent>
        <Card.Header>
          <NotificationTitle
            type={type}
            isRead={otherProps.seen}
            {...otherProps}
          />
        </Card.Header>
        <NotificationIconStatusChange
          style={{
            color: otherProps.seen ? svars.colorGrey : svars.accentColor,
            padding: '0px',
          }}
          name={otherProps.seen ? 'envelope open outline' : 'envelope outline'}
          showBadge={!otherProps.seen}
          help={
            otherProps.seen
              ? t({ id: `notifications-mark-status-unread` })
              : t({ id: `notifications-mark-status-read` })
          }
          onClick={(event) => handleIconClick(event)}
          gaCategory="Notifications"
          gaAction="Change notification status"
          gaLabel={`${otherProps.seen ? 'unread' : 'read'}`}
        />
        <Card.Description>
          <NotificationBody
            type={type}
            isRead={otherProps.seen}
            {...otherProps}
          />
        </Card.Description>
        <CardMeta>{dateDistance}</CardMeta>
      </CompactCardContent>
    </CardContainer>
  );
}

NotificationCard.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf([
    'PresetUserNotification',
    'CampaignPresetUserNotification',
  ]).isRequired,
  create_date: PropTypes.string.isRequired,
  details: PropTypes.shape().isRequired,
  providedLink: PropTypes.string,
  onToggle: PropTypes.func,
  seen: PropTypes.bool.isRequired,
};

NotificationCard.defaultProps = {
  providedLink: null,
  onToggle: null,
};

export default NotificationCard;
